import { bindable, inject }  from 'aurelia-framework';
import { AppContainer }      from 'resources/services/app-container';
import { BaseListViewModel } from 'base-list-view-model';
import { DialogService }     from 'aurelia-dialog';
import { FilterFormSchema }  from 'modules/reinspections/awaiting-payment/filter-form-schema';

@inject(AppContainer, DialogService, FilterFormSchema)
export class ListReinspectionsRequestAndPayment extends BaseListViewModel {

    listingId = 'reinspections-awaiting-payment-listing';

    @bindable headerTitle = 'listing.reinspections.awaiting-payment';
    @bindable repository;
    @bindable datatable;
    @bindable requestsViewModel;
    @bindable paymentsViewModel;
    @bindable legend = [
        { color: 'success', name: 'messages.on-time' },
        { color: 'warning', name: 'messages.less-than-two-weeks-remaining' },
        { color: 'danger', name: 'messages.overdue' },
    ];

    tab = 'requests';

    /**
     * Constructor
     *
     * @param appContainer
     * @param dialogService
     * @param filterFormSchema
     */
    constructor(appContainer, dialogService, filterFormSchema) {
        super(appContainer);

        this.dialogService    = dialogService;
        this.filterFormSchema = filterFormSchema;
    }

    /**
     * Handles canActivate event
     */
    canActivate() {
        return super.can([
            'reinspections.awaiting-payment.manage',
            'reinspections.awaiting-payment.view',
        ]);
    }

    /**
     * Handles activate event
     */
    activate(navigationInstruction) {
        super.activate(navigationInstruction);

        this.filterModel  = this.filterFormSchema.model(this);
        this.filterSchema = this.filterFormSchema.schema(this);
        this.viewModel    = this;

        if (typeof navigationInstruction.tab !== 'undefined') {
            this.tab = navigationInstruction.tab;
        }
    }

    /**
     * Reloads the datatables
     */
    reloadDatatables() {
        this.requestsViewModel.datatable.instance.reload();
        this.paymentsViewModel.datatable.instance.reload();
    }

    /**
     * Filters the datatables
     */
    filterDatatables() {
        this.appContainer.eventAggregator.publish('datatable-must-be-reloaded', { listingId: this.requestsViewModel.listingId, criteria: this.filterModel });
        this.appContainer.eventAggregator.publish('datatable-must-be-reloaded', { listingId: this.paymentsViewModel.listingId, criteria: this.filterModel });
    }

    /**
     * Resets the datatables
     */
    resetDatatables() {
        this.appContainer.eventAggregator.publish('datatable-filter-must-be-reseted', this.requestsViewModel.listingId);
        this.appContainer.eventAggregator.publish('datatable-filter-must-be-reseted', this.paymentsViewModel.listingId);
    }

}
