import { inject }                       from 'aurelia-framework';
import { InspectingEntitiesRepository } from 'modules/entities/inspecting-entities/services/repository';
import { ProcedureAssignment }          from 'modules/procedures/models/procedure-assignment';

@inject(InspectingEntitiesRepository)
export class FormSchema {

    /**
     * Model default values
     *
     * @type {{}}
     */
    modelDefaults = {};

    /**
     * Returns a new instance of the model
     *
     * @returns {ProcedureAssignment}
     */
    model() {
        let model = new ProcedureAssignment();

        model.assign(this.modelDefaults);

        return model;
    }

    /**
     * Constructor
     *
     * @param inspectingEntitiesRepository
     */
    constructor(inspectingEntitiesRepository) {
        this.inspectingEntitiesRepository = inspectingEntitiesRepository;
    }

    /**
     * Returns the form schema
     *
     * @param viewModel
     *
     * @returns {*[]}
     */
    schema(viewModel) {
        this.inspecting_entity_id = {
            type:         'select2',
            key:          'inspecting_entity_id',
            label:        'form.field.inspecting-entity',
            size:         12,
            options:      [],
            remoteSource: this.inspectingEntitiesRepository.active.bind(this.inspectingEntitiesRepository),
        };

        return [
            [this.inspecting_entity_id],
        ];
    }

}
