import { bindable, customElement, inject }                 from 'aurelia-framework';
import { AppContainer }                                    from 'resources/services/app-container';
import { BaseViewModel }                                   from 'base-view-model';
import { Downloader }                                      from 'resources/services/downloader';
import { ReinspectionPaymentCommunicationsRepository }     from 'modules/reinspections/awaiting-payment/payments/communications/services/repository';
import { ReinspectionPaymentCommunicationFilesRepository } from 'modules/reinspections/awaiting-payment/payments/communications/services/files-repository';

@customElement('reinspection-payment-communications')
@inject(AppContainer, Downloader, ReinspectionPaymentCommunicationsRepository, ReinspectionPaymentCommunicationFilesRepository)
export class ListReinspectionPaymentCommunications extends BaseViewModel {

    listingId = 'reinspections-payments-communications-listing';

    @bindable payment;
    repository;
    datatable;

    /**
     * Constructor
     *
     * @param appContainer
     * @param downloader
     * @param repository
     * @param filesRepository
     */
    constructor(appContainer, downloader, repository, filesRepository) {
        super(appContainer);

        this.downloader      = downloader;
        this.repository      = repository;
        this.filesRepository = filesRepository;
    }

    /**
     * Handles canActivate event
     */
    canActivate() {
        return super.can([
            'reinspections.awaiting-payment.manage',
            'reinspections.awaiting-payment.view',
        ]);
    }

    /**
     * Handles activate event
     */
    bind() {
        this.defineDatatable();
    }

    /**
     * Defines table columns
     */
    defineDatatable() {
        this.datatable = {
            repository:      {
                search: (criteria) => this.repository.search(this.payment.payment_id, criteria),
            },
            destroy:         false,
            destroySelected: false,
            actions:         [
                {
                    icon:    'icon-download7',
                    tooltip: 'form.button.download',
                    action:  (row) => this.downloadFile(row.file),
                    visible: (row) => row.file !== null,
                },
            ],
            options:         [],
            buttons:         [],
            selectable:      false,
            sorting:         {
                column:    3,
                direction: 'desc',
            },
            columns:         [
                {
                    data:  'type',
                    name:  'communication_types.name',
                    title: 'form.field.communication-type',
                },
                {
                    data:  'observations',
                    name:  'communications.observations',
                    title: 'form.field.observations',
                },
                {
                    data:  'creator',
                    name:  'creators.name',
                    title: 'form.field.registered-by',
                },
                {
                    data:            'created_at',
                    name:            'communications.created_at',
                    title:           'form.field.registered-at',
                    valueConverters: [
                        {
                            name: 'dateTimeFormat',
                        },
                    ],
                },
            ],
        };
    }

    /**
     * Downloads the given file
     *
     * @param file
     */
    downloadFile(file) {
        this.filesRepository
            .download(file.id)
            .then(result => this.downloader.download(result, file.display_name, file.extension));
    }

}
