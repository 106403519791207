import { BaseRepository } from 'modules/core/services/base-repository';

export class ReinspectionsAwaitingExecutionRepository extends BaseRepository {

    baseUrl = 'reinspections/awaiting-execution';

    /**
     * Exports a search given some criteria
     *
     * @param criteria
     *
     * @returns {*}
     */
    export(criteria) {
        return this.httpClient.rawPost(`${this.baseUrl}/export`, criteria)
            .then(response => {
                if (response.status !== 200) {
                    throw { status: false, message: 'Error' };
                } else {
                    return response.blob();
                }
            });
    }

    /**
     * Reassigns a record's maintenance technician
     *
     * @param id
     * @param data
     *
     * @returns {*}
     */
    reassign(id, data) {
        return this.httpClient.patch(`${this.baseUrl}/${id}/reassign`, data);
    }

    /**
     * Reassigns a record's maintenance technician
     *
     * @param id
     * @param data
     *
     * @returns {*}
     */
    reassign_inspector(id, data) {
        return this.httpClient.patch(`${this.baseUrl}/${id}/reassign-inspector`, data);
    }

    /**
     * Reschedules a record
     *
     * @param id
     * @param data
     *
     * @returns {*}
     */
    reschedule(id, data) {
        return this.httpClient.patch(`${this.baseUrl}/${id}/reschedule`, data);
    }

    /**
     * Closes a record's execution
     *
     * @param id
     * @param data
     *
     * @returns {*}
     */
    close(id, data) {
        return this.httpClient.put(`${this.baseUrl}/${id}/close`, data);
    }

}
